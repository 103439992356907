import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import { Typography } from "@mui/material";

export default function Main() {
  return (
    <Project
      title="DevFest 2025 [winner]"
      picture={
        <img
          alt="devfest2025"
          className="project-img"
          src={require("../pictures/devfest2025.png")}
          width={400}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          Launched an AI agent that makes health insurance more accessible by
          making it easier to understand medicine formularies and policies.
          Powered by ChatGPT Researcher and Python Flask, simply ask our agent
          questions about your coverage for better help. Won "Best use of
          Memenome API (Sponsored by Memenome)".
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://devpost.com/software/demystifyai">
            Devpost Submission
          </ProjectLink>
          <ProjectLink
            icon="github"
            link="https://github.com/darenhua/devfest-2025"
          >
            Github
          </ProjectLink>

          <ProjectLink
            icon="youtube"
            link="https://www.youtube.com/watch?v=Jp8X2aAUR0A"
          >
            Video
          </ProjectLink>
          <ProjectLink
            icon="youtube"
            link="https://www.youtube.com/shorts/MNIHUCViNiA"
          >
            Lebron James
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
