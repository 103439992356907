import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";

export default function Visaq() {
  return (
    <Project
      title="Visaq"
      picture={
        <img
          alt="screenshot of visaq"
          className="project-img"
          src={require("../pictures/visaq.png")}
          width={600}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          This website displays the queue times for visa appointments from
          various posts around the world using line charts from <b>Chart.js</b>.
          Numerical data is collected from US Global Visa Wait Times, and
          textual data is ai generated.
          <br />
          <br />A <b>Crontab</b> runs a <b>Shell</b> script, which runs a{" "}
          <b>Python</b> script to <b>webscrape</b> for data and a <b>Js</b>{" "}
          script to upload it to <b>MongoDB</b>. The front end is built with{" "}
          <b>React</b> and <b>MUI</b>. The backend uses <b>Node.js</b>,{" "}
          <b>Express</b>, and <b>MongoDB</b>.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://www.visaq.app">Live</ProjectLink>
          <ProjectLink link="https://github.com/visaq-bt/fe" icon="github">
            Front End
          </ProjectLink>
          <ProjectLink link="https://github.com/visaq-bt/be" icon="github">
            Back End
          </ProjectLink>
          <ProjectLink link="https://github.com/visaq-bt/crontab" icon="github">
            Crontab
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
