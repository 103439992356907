import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import ReactPlayer from "react-player";
import { Typography } from "@mui/material";

export default function Main() {
  return (
    <Project
      title="Cybersecurity Tool (beta)"
      picture={
        <img
          alt="cyber security"
          className="project-img"
          // src={require("../pictures/devfest2025.png")}
          width={400}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          Partnered with Eric Olsen (cybersecurity director at Jetblue) to
          develop a cost effective cybersecurity tool for businesses to protect
          their digital assets. This application replicates what an attacker
          might do to gain intelligence to exploit vulnerabilities. Key features
          include domain file uploads, notification integrations, recurring
          scans, and team management. Technologies include MongoDB, React,
          Express, Node.js, Stripe. Deployed using AWS ec2, Route 53, Nginx,
          Github, Namecheap.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://marzipans.app">Marzipans.app</ProjectLink>

          <ProjectLink
            icon="linkedin"
            link="https://www.linkedin.com/in/eolsondc/"
          >
            Eric Olsen
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
