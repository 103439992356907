import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function Project({ title, picture, text, links }) {
  const navigate = useNavigate();

  return (
    <Stack sx={styles.container}>
      <Stack direction="row" sx={styles.top}>
        <KeyboardArrowLeftIcon sx={styles.icon} onClick={() => navigate("/")} />
        <Typography align="center" sx={styles.title}>
          {title}
        </Typography>
      </Stack>

      <Stack sx={styles.content}>
        {picture}
        {text}
        {links}
      </Stack>
    </Stack>
  );
}

const styles = {
  container: {
    width: 1,
    height: 1,
    // minHeight: 1,
    border: 0.1,

    // backgroundColor: "black",
  },

  top: {
    width: 1,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: 0.1,
  },

  icon: {
    width: 40,
    height: 40,
    "&:hover": { cursor: "pointer" },
  },

  title: { fontSize: 30, width: 1 },

  content: {
    width: 1,
    height: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    overflow: "auto",
  },
};
